<template>
  <CModal
    :title="program.Id ? 'Cập nhật CT' : 'Thêm CT'"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <CRow>
          <CCol md="12">
            <CInput
              label="Tên chương trình*"
              placeholder="Tên chương trình"
              v-model="program.Name"
              :add-input-classes="{
                'is-invalid': !!inValidObject['program.Name'],
              }"
              :invalid-feedback="inValidObject['program.Name']"
            />
          </CCol>

          <CCol md="12">
            <div class="form-row">
              <CCol>
                <div role="group" class="form-group">
                  <label>
                    Thời gian bắt đầu
                  </label>
                  <datetime
                    type="date"
                    v-model="program.FromDate"
                    format="dd/MM/yyyy"
                    :input-class="{
                      'form-control': true,
                      'is-invalid': !!inValidObject['program.FromDate'],
                    }"
                    :class="{
                      'is-invalid': !!inValidObject['program.FromDate'],
                    }"
                    value-zone="local"
                  />
                  <div class="invalid-feedback">
                    {{ inValidObject["program.FromDate"] }}
                  </div>
                </div>
              </CCol>

              <CCol>
                <div role="group" class="form-group">
                  <label>
                    Thời gian kết thúc
                  </label>
                  <div class="form-row">
                    <div :class="`${program.ToDate ? 'col-10' : 'col-12'}`">
                      <datetime
                        type="date"
                        v-model="program.ToDate"
                        format="dd/MM/yyyy"
                        :input-class="{
                          'form-control': true,
                          'is-invalid': !!inValidObject['program.ToDate'],
                        }"
                        :class="{
                          'is-invalid': !!inValidObject['program.ToDate'],
                        }"
                        value-zone="local"
                      />
                    </div>
                    <div v-if="program.ToDate" class="col-2">
                      <button
                        type="button"
                        class="btn btn-light"
                        @click="program.ToDate = null"
                      >
                        x
                      </button>
                    </div>
                  </div>
                  <div class="invalid-feedback">
                    {{ inValidObject["program.ToDate"] }}
                  </div>
                </div>
              </CCol>
            </div>
          </CCol>

          <CCol md="12">
            <CTextarea
              label="Mô tả"
              placeholder="Mô tả"
              v-model="program.Description"
              :add-input-classes="{
                'is-invalid': !!inValidObject['program.Description'],
              }"
              :invalid-feedback="inValidObject['program.Description']"
            />
          </CCol>

          <CCol md="12">
            <CSelect
              label="Trạng thái"
              placeholder="Chọn Trạng thái"
              :value.sync="program.Status"
              :options="
                Object.keys($const.STATUS_TEXT).map((_) => {
                  return {
                    value: _,
                    label: $const.STATUS_TEXT[_],
                  };
                })
              "
              :add-input-classes="{
                'is-invalid': !!inValidObject['program.Status'],
              }"
              :invalid-feedback="inValidObject['program.Status']"
            />
          </CCol>
        </CRow>
      </div>
    </template>
    <template v-slot:footer>
      <CButton class="btn btn-primary" @click="saveProgram" :disabled="saving">
        <CIcon name="cil-save" />
        Lưu thông tin
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

export default {
  components: {
    Datetime,
  },
  props: ["editing", "programId"],
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      program: this.newProgram(),
      saving: false,
    };
  },
  watch: {
    async programId(val) {
      await this.loadProgramInfo(val);
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:programId", null);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },
  },

  methods: {
    newProgram() {
      return {
        Id: 0,
        Name: null,
        FromDate: new Date().toISOString(),
        ToDate: null, //new Date().toISOString(),
        Description: null,
        Status: this.$const.STATUS.Active.toString(),
      };
    },

    async loadProgramInfo(programId) {
      try {
        this.inValidObject = {};
        if (programId) {
          let filters = [];
          programId && filters.push(`Id eq ${programId}`);
          let resp = await this.$http.get(`odata/LuckyProgram`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $select: "Id,Name,FromDate,ToDate,Description,Status",
            },
          });
          if (resp && resp.status == 200) {
            let _ = resp.data.value[0];
            this.program = {
              Id: _.Id,
              Name: _.Name,
              FromDate: _.FromDate,
              ToDate: _.ToDate,
              Description: _.Description,
              Status: _.Status.toString(),
            };
          }
        } else {
          this.program = this.newProgram();
        }
      } catch (error) {
        alert(error);
      }
    },

    async saveProgram() {
      let program = this.program;
      this.inValidObject = this.checkValid(program);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let programData = {
          Name: program.Name,
          FromDate: program.FromDate,
          ToDate: program.ToDate,
          Description: program.Description,
          Status: parseInt(program.Status),
        };
        // Customer
        if (!program.Id) {
          // post
          let resp = await this.$http.post(`odata/LuckyProgram`, programData);
          if (resp && resp.status == 201) {
            this.detailModal = false;
            this.$emit("reload");
          }
        } else {
          // patch
          let resp = await this.$http.patch(
            `odata/LuckyProgram/${program.Id}`,
            programData
          );
          if (resp && resp.status == 204) {
            this.detailModal = false;
            this.$emit("reload");
          }
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(program) {
      let inValidObject = {};

      if (!program.Name)
        inValidObject["program.Name"] = "Vui lòng nhập Tên Chương Trình!";
      if (!program.FromDate)
        inValidObject["program.FromDate"] = "Vui lòng nhập Ngày bắt đầu!";
      // if (!program.ToDate)
      //   inValidObject["program.ToDate"] = "Vui lòng nhập Ngày kết thúc!";
      if (!program.Status)
        inValidObject["program.Status"] = "Vui lòng chọn Trạng Thái!";

      return inValidObject;
    },
  },
};
</script>
