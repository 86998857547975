<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cilMobileLandscape" />
          Danh sách chương trình
        </span>
        <CButton
          color="success"
          size="sm"
          class="float-right"
          @click.prevent="
            (e) => {
              editing = true;
              programId = 0;
            }
          "
        >
          <CIcon name="cil-plus" />
          Thêm chương trình
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="4">
            <CSelect
              label="Trạng thái"
              placeholder="Trạng thái"
              :value.sync="programStatus"
              :options="[
                { value: null, label: 'Tất cả' },
                ...Object.keys(statusList).map((_) => {
                  return {
                    value: _,
                    label: statusList[_],
                  };
                }),
              ]"
              :horizontal="{
                label: 'col-4 text-right',
                input: 'col-8',
              }"
            />
          </CCol>
          <CCol md="4">
            <CInput
              label="Tên chương trình"
              placeholder="Tên chương trình"
              v-model="programName"
              :horizontal="{
                label: 'col-4 text-right',
                input: 'col-8',
              }"
              @keyup.enter="doFilter"
            />
          </CCol>
          <CCol md="3" class="text-center">
            <CButton color="primary" class="mr-2 mb-3" @click="doFilter">
              <CIcon name="cil-search" custom-classes="c-icon m-0" />
              Tìm kiếm
            </CButton>

            <CButton color="secondary" class="mb-3" @click="clear">
              <CIcon name="cil-x-circle" custom-classes="c-icon m-0" />
              Đặt lại
            </CButton>
          </CCol>
        </CRow>
        <odata-table
          ref="lstProgram"
          url="odata/LuckyProgram"
          :filter="filter"
          select="Id,Name,FromDate,ToDate,Description,Status,CreatedAt"
          sortBy="CreatedAt desc"
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            Name: {
              field: 'Name',
              display: 'Tên chương trình',
              sortable: true,
              style: 'min-width: 120px',
            },
            DateRange: {
              field: 'DateRange',
              display: 'Thời gian',
              sortable: false,
              style: 'min-width: 150px',
            },
            Description: {
              field: 'Description',
              display: 'Mô tả',
              sortable: true,
              style: 'min-width: 120px',
            },
            Status: {
              field: 'Status',
              display: 'Trạng thái',
              sortable: true,
              style: 'min-width: 100px',
            },
            CreatedAt: {
              field: 'CreatedAt',
              display: 'Ngày tạo',
              sortable: true,
              style: 'min-width: 120px',
            },
            OnLive: {
              field: 'OnLive',
              display: 'Đang chạy',
              sortable: false,
              style: 'min-width: 80px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-for="(row, i) in rows" :key="`tr-${i}`">
              <td>{{ i + 1 + ($refs.lstProgram.page - 1) * pageSize }}.</td>
              <td>
                <a
                  href="javascript:"
                  class="text-primary"
                  :title="`Cập nhật: ${row.Name}`"
                  @click="
                    (e) => {
                      programId = row.Id;
                      editing = true;
                    }
                  "
                >
                  <CIcon name="cil-pencil" />
                  {{ row.Name }}
                </a>
              </td>
              <td style="max-width:200px;">
                Từ:
                {{
                  $moment
                    .utc(row.FromDate)
                    .utcOffset("-08:00")
                    .format("DD/MM/YY")
                }}
                <template v-if="row.ToDate">
                  - Đến:
                  {{
                    $moment
                      .utc(row.ToDate)
                      .utcOffset("-08:00")
                      .format("DD/MM/YY")
                  }}
                </template>
              </td>
              <td>
                {{ row.Description }}
              </td>
              <td>
                <CBadge :color="getBadge(row.Status)">
                  {{ $const.STATUS_TEXT[row.Status] }}
                </CBadge>
              </td>
              <td>
                {{
                  $moment
                    .utc(row.CreatedAt)
                    .utcOffset("-08:00")
                    .format("DD/MM/YY HH:mm")
                }}
              </td>
              <td>
                <CIcon
                  v-if="isOnLive(row)"
                  name="cil-badge"
                  custom-classes="c-icon c-icon-lg text-danger"
                />
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>
    <detail
      :editing.sync="editing"
      :programId.sync="programId"
      @reload="$refs.lstProgram.loadData()"
    />
  </div>
</template>

<script>
import odataTable from "@/components/odataTable.vue";
import detail from "./Detail.vue";

export default {
  name: "LuckyProgram",
  components: {
    odataTable,
    detail,
  },
  data() {
    return {
      editing: false,
      programId: 0,
      pageSize: 10,
      programStatus: null,
      statusList: this.$const.STATUS_TEXT,
      programName: null,
      filters: null,
    };
  },
  computed: {
    filter() {
      let filters = [];

      console.log(filters);
      if (this.filters && this.filters.length) {
        filters = [...this.filters];
      }

      return filters.join(" and ");
    },
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case this.$const.STATUS.Active:
          return "success";
        case this.$const.STATUS.InActive:
          return "secondary";
        default:
          "primary";
      }
    },
    doFilter() {
      let filters = [];
      this.programStatus && filters.push(`Status eq ${this.programStatus}`);
      this.programName && filters.push(`contains(Name,'${this.programName}')`);
      filters.join(" and ");

      if (JSON.stringify(this.filters) != JSON.stringify(filters)) {
        this.filters = filters;
      } else {
        this.$refs.lstProgram.loadData();
      }
    },
    clear() {
      this.programStatus = this.$const.STATUS.Active.toString();
      this.programName = null;
    },

    isOnLive(program) {
      return (
        program.Status == this.$const.STATUS.Active &&
        this.$moment(new Date()).isAfter(program.FromDate) &&
        this.$moment(new Date()).isBefore(program.ToDate)
      );
    },
  },
};
</script>
