var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.program.Id ? 'Cập nhật CT' : 'Thêm CT',"show":_vm.detailModal,"closeOnBackdrop":false,"centered":""},on:{"update:show":function($event){_vm.detailModal=$event}},scopedSlots:_vm._u([{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body overflow-auto",staticStyle:{"max-height":"calc(100vh - 11.6rem)"}},[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Tên chương trình*","placeholder":"Tên chương trình","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['program.Name'],
            },"invalid-feedback":_vm.inValidObject['program.Name']},model:{value:(_vm.program.Name),callback:function ($$v) {_vm.$set(_vm.program, "Name", $$v)},expression:"program.Name"}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-row"},[_c('CCol',[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',[_vm._v(" Thời gian bắt đầu ")]),_c('datetime',{class:{
                    'is-invalid': !!_vm.inValidObject['program.FromDate'],
                  },attrs:{"type":"date","format":"dd/MM/yyyy","input-class":{
                    'form-control': true,
                    'is-invalid': !!_vm.inValidObject['program.FromDate'],
                  },"value-zone":"local"},model:{value:(_vm.program.FromDate),callback:function ($$v) {_vm.$set(_vm.program, "FromDate", $$v)},expression:"program.FromDate"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["program.FromDate"])+" ")])],1)]),_c('CCol',[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',[_vm._v(" Thời gian kết thúc ")]),_c('div',{staticClass:"form-row"},[_c('div',{class:("" + (_vm.program.ToDate ? 'col-10' : 'col-12'))},[_c('datetime',{class:{
                        'is-invalid': !!_vm.inValidObject['program.ToDate'],
                      },attrs:{"type":"date","format":"dd/MM/yyyy","input-class":{
                        'form-control': true,
                        'is-invalid': !!_vm.inValidObject['program.ToDate'],
                      },"value-zone":"local"},model:{value:(_vm.program.ToDate),callback:function ($$v) {_vm.$set(_vm.program, "ToDate", $$v)},expression:"program.ToDate"}})],1),(_vm.program.ToDate)?_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"btn btn-light",attrs:{"type":"button"},on:{"click":function($event){_vm.program.ToDate = null}}},[_vm._v(" x ")])]):_vm._e()]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["program.ToDate"])+" ")])])])],1)]),_c('CCol',{attrs:{"md":"12"}},[_c('CTextarea',{attrs:{"label":"Mô tả","placeholder":"Mô tả","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['program.Description'],
            },"invalid-feedback":_vm.inValidObject['program.Description']},model:{value:(_vm.program.Description),callback:function ($$v) {_vm.$set(_vm.program, "Description", $$v)},expression:"program.Description"}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CSelect',{attrs:{"label":"Trạng thái","placeholder":"Chọn Trạng thái","value":_vm.program.Status,"options":Object.keys(_vm.$const.STATUS_TEXT).map(function (_) {
                return {
                  value: _,
                  label: _vm.$const.STATUS_TEXT[_],
                };
              }),"add-input-classes":{
              'is-invalid': !!_vm.inValidObject['program.Status'],
            },"invalid-feedback":_vm.inValidObject['program.Status']},on:{"update:value":function($event){return _vm.$set(_vm.program, "Status", $event)}}})],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.saving},on:{"click":_vm.saveProgram}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Lưu thông tin ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }